// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import * as jQuery from 'jquery'
import 'slick-carousel/slick/slick.js'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import "styles/styles"
import * as feather from 'feather-icons';
import { Luminous, LuminousGallery } from 'luminous-lightbox';
import "luminous-lightbox/dist/luminous-basic.css";
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

const images = require.context('../images', true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const replaceKanaHalfToFull = (str) => {
  var kanaMap = {
    'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
    'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
    'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
    'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
    'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
    'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
    'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
    'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
    'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
    'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
    'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
    'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
    'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
    'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
    'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
    'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
    'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
    'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
    '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
  };
  let reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
  return str.replace(reg, function(s){
    return kanaMap[s];
  }).replace(/ﾞ/g, '゛').replace(/ﾟ/g, '゜');
};

const toKata = (str) => {
  if (!str) return undefined;
  if (!/^[\u3041-\u3096]+$/.test(str)) return str;
  const s = str.replace(/[\u3041-\u3096]/g, ch =>
    String.fromCharCode(ch.charCodeAt(0) + 0x60)
  );
  return replaceKanaHalfToFull(s);
};

const toKanaInput = (q) => {
  const $input = jQuery(q, this);
  $input.val(toKata($input.val()));
};

jQuery(document).on("turbolinks:before-cache", () => {
  const sliders = document.querySelectorAll('.slick-initialized');
  sliders.forEach(item => {
    jQuery(item).slick('unslick');
  });
});

jQuery(document).on('turbolinks:load', () => {
  new LuminousGallery(document.querySelectorAll('a.luminous'));

  jQuery('.main-carousel').slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
  });

  feather.replace();

  if(document.getElementById('swiffycontainer')) {
    const container = document.getElementById('swiffycontainer');
    while (container.firstChild) {
      container.removeChild(container.lastChild);
    }
    const stage = new swiffy.Stage(container, window.swiffyobject, {  });
    stage.start();
  }

  jQuery('.hamburger').click(() => {
    jQuery('#dropdown-menu').slideDown('fast');
  });

  jQuery('.menu-close svg').click(() => {
    jQuery('#dropdown-menu').slideUp('fast');
  });

  jQuery('#dropdown-menu ul li a').click(() => {
    jQuery('#dropdown-menu').slideUp(0);
  });

  jQuery('#new_shop_search_form').submit((e) => {
    toKanaInput('#shop_search_form_keyword');
    toKanaInput('#shop_search_form_prefecture_name');
    jQuery(this).submit();
  });

  jQuery('#new_winning_search_form').submit((e) => {
    toKanaInput('#winning_search_form_prefecture_name');
    jQuery(this).submit();
  });

  jQuery('#new_company_search_form').submit((e) => {
    toKanaInput('#company_search_form_prefecture_name');
    jQuery(this).submit();
  });

  jQuery('#new_news_search_form').submit((e) => {
    toKanaInput('#news_search_form_prefecture_name');
    jQuery(this).submit();
  });

  jQuery('#goto-top-sp,#goto-top-pc').click(() => {
    jQuery('html, body').animate({scrollTop: 0});
  });

  jQuery('.lucky-number .images a').click((e) => {
    jQuery('#lucky_number_form_n')
      .val(jQuery(e.currentTarget).data('n'));
    jQuery('#new_lucky_number_form')
      .submit();
  });

  if ( navigator.geolocation ) {
    jQuery('#shops-geolocation-search').click(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        jQuery('#shop_location_search_form_latitude')
          .val(position.coords.latitude);
        jQuery('#shop_location_search_form_longitude')
          .val(position.coords.longitude);
        jQuery('#new_shop_location_search_form').submit();
      }, () => {
        console.log('getCurrentPosition error');
      });
    });

    jQuery('#winnings-geolocation-search').click(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        jQuery('#winning_location_search_form_latitude')
          .val(position.coords.latitude);
        jQuery('#winning_location_search_form_longitude')
          .val(position.coords.longitude);
        jQuery('#new_winning_location_search_form').submit();
      }, () => {
        console.log('getCurrentPosition error');
      });
    });
  }
})

require("@rails/actiontext")
